var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VCard',[_c('VCardTitle',[_vm._v(" All Users "),_c('VSpacer'),_c('VTextField',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":"","placeholder":"longthanh","prepend-icon":"fa-search","hint":"Search by email, name or role, ..."},model:{value:(_vm.queryUser),callback:function ($$v) {_vm.queryUser=$$v},expression:"queryUser"}})],1),_c('VDataTable',{attrs:{"headers":_vm.headers,"items":_vm.users,"multi-sort":"","loading":_vm.tableLoading,"search":_vm.queryUser},scopedSlots:_vm._u([{key:"item.user_name",fn:function(ref){
var item = ref.item;
return [_c('VEditDialog',{attrs:{"large":"","return-value":item.name},on:{"update:returnValue":function($event){return _vm.$set(item, "name", $event)},"update:return-value":function($event){return _vm.$set(item, "name", $event)},"save":function($event){return _vm.endEditName(item, true)},"cancel":function($event){return _vm.endEditName(item, false)},"close":function($event){return _vm.endEditName(item, false)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('VTextField',{attrs:{"label":"Edit","single-line":"","loading":item.__loadingName,"counter":""},model:{value:(item.__nameModel),callback:function ($$v) {_vm.$set(item, "__nameModel", $$v)},expression:"item.__nameModel"}})]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex"},[_vm._v(" "+_vm._s(item.user_name)+" "),_c('VIcon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("fa fa-edit")])],1)])]}},{key:"item.role_list",fn:function(ref){
var item = ref.item;
return [_vm._l((item.role_list),function(role,i){return _c('VChip',{key:((item.id) + "[roles][" + i),staticClass:"ma-1",attrs:{"close":!item.___isEditing && role !='superadmin',"color":role =='superadmin' ? 'warning' : ''},on:{"click:close":function($event){return _vm.removeRole(item, role)}}},[_vm._v(" "+_vm._s(role)+" ")])}),(item.___isEditing)?[_c('div',{staticClass:"d-flex",staticStyle:{"width":"80%","max-width":"400px"}},[_c('VCombobox',{attrs:{"items":_vm.roles.filter(function (x) { return !item.role_list.includes(x) && x != 'superadmin'; }),"multiple":"","chips":"","dense":"","hide-details":"auto","loading":_vm.roleLoading,"hide-selected":""},model:{value:(item.__rolesModel),callback:function ($$v) {_vm.$set(item, "__rolesModel", $$v)},expression:"item.__rolesModel"}}),_c('VBtn',{staticClass:"ml-2 px-0",attrs:{"fab":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.endAddUserRoles(item, false)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v("fa-times")])],1),_c('VBtn',{staticClass:"ml-2 px-0",attrs:{"fab":"","small":"","color":"info","loading":item.__loadingRole},on:{"click":function($event){return _vm.endAddUserRoles(item, true)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v("fa-check")])],1)],1)]:[_c('VBtn',{staticClass:"ml-2 px-0",attrs:{"fab":"","x-small":"","color":"info","loading":item.__loadingRole},on:{"click":function($event){return _vm.startAddUserRoles(item)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v("fa-plus")])],1)]]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VBtn',{staticClass:"px-0",attrs:{"fab":"","small":"","color":"teal"},on:{"click":function($event){return _vm.viewUserPolicy(item)}}},[_c('VIcon',{attrs:{"small":""}},[_vm._v("far fa-edit")])],1)]}}],null,true)}),_c('SlideInPanel',{attrs:{"noPortal":""},model:{value:(_vm.panelActive),callback:function ($$v) {_vm.panelActive=$$v},expression:"panelActive"}},[_c('h2',[_vm._v("Edit Permissions")]),(_vm.selectedUser)?_c('VRow',{staticClass:"mt-4"},[_c('VCol',{attrs:{"cols":"6"}},[_c('VTextField',{attrs:{"dense":"","outlined":"","label":"Name","readonly":"","hide-details":"auto"},model:{value:(_vm.selectedUser.user_name),callback:function ($$v) {_vm.$set(_vm.selectedUser, "user_name", $$v)},expression:"selectedUser.user_name"}})],1),_c('VCol',{attrs:{"cols":"6"}},[_c('VTextField',{attrs:{"dense":"","outlined":"","label":"Email","readonly":"","hide-details":"auto"},model:{value:(_vm.selectedUser.email),callback:function ($$v) {_vm.$set(_vm.selectedUser, "email", $$v)},expression:"selectedUser.email"}})],1)],1):_vm._e(),_c('VRow',{staticClass:"mt-4"},[(_vm.selectedUser)?_c('VCol',{attrs:{"cols":"6"}},[_c('VCard',{attrs:{"loading":_vm.policyPanel.loadingImplicit}},[_c('VCardTitle',[_c('h5',{staticClass:"mr-8"},[_vm._v("Current Policies")]),_c('VTextField',{attrs:{"append-icon":"fa-search","label":"Search"},model:{value:(_vm.policyPanel.queryImplicit),callback:function ($$v) {_vm.$set(_vm.policyPanel, "queryImplicit", $$v)},expression:"policyPanel.queryImplicit"}})],1),_c('VVirtualScroll',{attrs:{"items":_vm.querriedImplicitPolicy,"height":"400","item-height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('VListItem',{key:((item.Method) + ":" + (item.Path))},[_c('VListItemAction',[_c('VBtn',{attrs:{"fab":"","x-small":"","depressed":"","color":"error","disabled":item.Role !== _vm.selectedUser.email,"loading":item.__loading},on:{"click":function($event){return _vm.doDeleteUserPolicy(item)}}},[_c('VIcon',{attrs:{"x-small":""}},[_vm._v("fa-minus")])],1)],1),_c('VListItemContent',[_c('VListItemTitle',[_c('strong',{class:_vm.getMethodColor(item.Method)},[_vm._v(_vm._s(item.Method))]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.Path))])])],1)],1)]}}],null,false,1349176741)})],1)],1):_vm._e(),_c('VCol',{attrs:{"cols":"6"}},[_c('VCard',{attrs:{"loading":_vm.policyLoading}},[_c('VCardTitle',[_c('h5',{staticClass:"mr-8"},[_vm._v("Available Policies")]),_c('VTextField',{attrs:{"append-icon":"fa-search","label":"Search"},model:{value:(_vm.policyPanel.query),callback:function ($$v) {_vm.$set(_vm.policyPanel, "query", $$v)},expression:"policyPanel.query"}})],1),_c('VVirtualScroll',{attrs:{"items":_vm.querriedPolicies,"height":"400","item-height":"56"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('VListItem',{key:((item.Method) + ":" + (item.Path))},[_c('VListItemAction',[_c('VBtn',{attrs:{"fab":"","x-small":"","depressed":"","color":"primary","loading":item.__loading},on:{"click":function($event){return _vm.doAddUserPolicy(item)}}},[_c('VIcon',{attrs:{"x-small":""}},[_vm._v("fa-plus")])],1)],1),_c('VListItemContent',[_c('VListItemTitle',[_c('strong',{class:_vm.getMethodColor(item.Method)},[_vm._v(" "+_vm._s(item.Method)+" ")]),_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(item.Path))])])],1)],1)]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }