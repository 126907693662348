<template>
  <VCard>
    <VCardTitle>
      All Users
      <VSpacer />
      <VTextField
        v-model="queryUser"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        placeholder="longthanh"
        prepend-icon="fa-search"
        hint="Search by email, name or role, ..."
      />
    </VCardTitle>
    <VDataTable
      :headers="headers"
      :items="users"
      multi-sort
      :loading="tableLoading"
      :search="queryUser"
    >
      <template v-slot:[`item.user_name`]="{ item }">
        <VEditDialog
          large
          :return-value.sync="item.name"
          @save="endEditName(item, true)"
          @cancel="endEditName(item, false)"
          @close="endEditName(item, false)"
        >
          <div class="d-flex">
            {{ item.user_name }}
            <VIcon small class="ml-1">fa fa-edit</VIcon>
          </div>
          <template v-slot:input>
            <VTextField
              v-model="item.__nameModel"
              label="Edit"
              single-line
              :loading="item.__loadingName"
              counter
            />
          </template>
        </VEditDialog>
      </template>
      <template v-slot:[`item.role_list`]="{ item }">
        <VChip
          class="ma-1"
          :close="!item.___isEditing && role !='superadmin'"
          v-for="(role, i) in item.role_list"
          :key="`${item.id}[roles][${i}`"
          @click:close="removeRole(item, role)"
          :color="role =='superadmin' ? 'warning' : ''"
        >
          {{ role }}
        </VChip>
        <template v-if="item.___isEditing">
          <div class="d-flex" style="width: 80%; max-width: 400px;">
            <VCombobox
              v-model="item.__rolesModel"
              :items="roles.filter((x) => !item.role_list.includes(x) && x != 'superadmin')"
              multiple
              chips
              dense
              hide-details="auto"
              :loading="roleLoading"
              hide-selected
            />
            <VBtn
              class="ml-2 px-0"
              fab
              small
              color="secondary"
              @click="endAddUserRoles(item, false)"
            >
              <VIcon small class="">fa-times</VIcon>
            </VBtn>
            <VBtn
              class="ml-2 px-0"
              fab
              small
              color="info"
              @click="endAddUserRoles(item, true)"
              :loading="item.__loadingRole"
            >
              <VIcon small class="">fa-check</VIcon>
            </VBtn>
          </div>
        </template>
        <template v-else>
          <VBtn
            class="ml-2 px-0"
            fab
            x-small
            color="info"
            @click="startAddUserRoles(item)"
            :loading="item.__loadingRole"
          >
            <VIcon small class="">fa-plus</VIcon>
          </VBtn>
        </template>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <VBtn
          class="px-0"
          fab
          small
          color="teal"
          @click="viewUserPolicy(item)"
        >
          <VIcon small class="">far fa-edit</VIcon>
        </VBtn>
      </template>
    </VDataTable>
    <SlideInPanel v-model="panelActive" noPortal>
      <h2>Edit Permissions</h2>
      <VRow class="mt-4" v-if="selectedUser">
        <VCol cols="6">
          <VTextField
            dense
            outlined
            label="Name"
            readonly
            hide-details="auto"
            v-model="selectedUser.user_name"
          />
        </VCol>
        <VCol cols="6">
          <VTextField
            dense
            outlined
            label="Email"
            readonly
            hide-details="auto"
            v-model="selectedUser.email"
          />
        </VCol>
      </VRow>
      <VRow class="mt-4">
        <VCol cols="6" v-if="selectedUser">
          <VCard :loading="policyPanel.loadingImplicit">
            <VCardTitle>
              <h5 class="mr-8">Current Policies</h5>
              <VTextField
                append-icon="fa-search"
                label="Search"
                v-model="policyPanel.queryImplicit"
              />
            </VCardTitle>
            <VVirtualScroll
              :items="querriedImplicitPolicy"
              height="400"
              item-height="56"
            >
              <template #default="{ item }">
                <VListItem :key="`${item.Method}:${item.Path}`">
                  <VListItemAction>
                    <VBtn
                      fab
                      x-small
                      depressed
                      color="error"
                      :disabled="item.Role !== selectedUser.email"
                      @click="doDeleteUserPolicy(item)"
                      :loading="item.__loading"
                    >
                      <VIcon x-small>fa-minus</VIcon>
                    </VBtn>
                  </VListItemAction>
                  <VListItemContent>
                    <VListItemTitle>
                      <strong :class="getMethodColor(item.Method)">{{
                        item.Method
                      }}</strong>
                      <span class="ml-2">{{ item.Path }}</span>
                    </VListItemTitle>
                  </VListItemContent>
                </VListItem>
              </template>
            </VVirtualScroll>
          </VCard>
        </VCol>
        <VCol cols="6">
          <VCard :loading="policyLoading">
            <VCardTitle>
              <h5 class="mr-8">Available Policies</h5>
              <VTextField
                append-icon="fa-search"
                label="Search"
                v-model="policyPanel.query"
              />
            </VCardTitle>
            <VVirtualScroll
              :items="querriedPolicies"
              height="400"
              item-height="56"
            >
              <template #default="{ item }">
                <VListItem :key="`${item.Method}:${item.Path}`">
                  <VListItemAction>
                    <VBtn
                      fab
                      x-small
                      depressed
                      color="primary"
                      @click="doAddUserPolicy(item)"
                      :loading="item.__loading"
                    >
                      <VIcon x-small>fa-plus</VIcon>
                    </VBtn>
                  </VListItemAction>
                  <VListItemContent>
                    <VListItemTitle>
                      <strong :class="getMethodColor(item.Method)">
                        {{ item.Method }}
                      </strong>
                      <span class="ml-2">{{ item.Path }}</span>
                    </VListItemTitle>
                  </VListItemContent>
                </VListItem>
              </template>
            </VVirtualScroll>
          </VCard>
        </VCol>
      </VRow>
    </SlideInPanel>
  </VCard>
</template>

<script>
import SlideInPanel from "@/components/essenstials/SlideInPanel.vue";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import errorHandle from "@/mixins/errorHandle";
import { getMethodColor } from "@/utils/vuetify";

export default {
  name: "ManageUser",
  components: { SlideInPanel },
  mixins: [errorHandle()],
  data: () => ({
    panelActive: false,
    tableLoading: false,
    users: [],
    queryUser: "",
    policies: [],
    policyPanel: {
      implicit: [],
      query: "",
      queryImplicit: "",
      loadingImplicit: false,
    },
    selectedUser: null,
  }),
  computed: {
    ...mapGetters({
      me: "authUser",
    }),
    ...mapState("su", {
      allUsers: (state) => state.user.list,
      roles: (state) => state.role.list,
      roleLoading: (state) => state.role.loading,
      allPolicies: (state) => state.policy.list,
      policyLoading: (state) => state.policy.loading,
    }),
    headers() {
      return [
        { text: "Name", value: "user_name" },
        {
          text: "Email",
          align: "start",
          sortable: true,
          value: "email",
        },
        { text: "Roles", value: "role_list", sortable: false },
        { text: "Policy", value: "actions", sortable: false },
      ];
    },
    querriedPolicies() {
      const q = (this.policyPanel.query || "").toLowerCase();
      const implicit = this.policyPanel.implicit || [];
      return this.policies
        .filter((x) => {
          if (!this.selectedUser) {
            return true;
          }
          return (
            implicit.findIndex(
              (p) =>
                p.Method == x.Method &&
                p.Path == x.Path &&
                p.Role == this.selectedUser.email
            ) < 0
          );
        })
        .filter((x) => {
          const method = (x.Method || "").toLowerCase();
          const path = (x.Path || "").toLowerCase();
          return method.indexOf(q) > -1 || path.indexOf(q) > -1;
        });
    },
    querriedImplicitPolicy() {
      const q = (this.policyPanel.queryImplicit || "").toLowerCase();
      return (this.policyPanel.implicit || []).filter((x) => {
        const method = (x.Method || "").toLowerCase();
        const path = (x.Path || "").toLowerCase();
        return method.indexOf(q) > -1 || path.indexOf(q) > -1;
      });
    },
  },
  mounted() {
    this.fetchUsers();
  },
  methods: {
    ...mapMutations({
      OpenError: "OpenError",
    }),
    ...mapActions("su", {
      getUsers: "fetchUsers",
      addUserRoles: "addUserRoles",
      deleteUserRole: "deleteUserRole",
      getPolicies: "fetchPolicies",
      getImplicitPolicies: "fetchImplicitPolicies",
      addUserPolicy: "addUserPolicy",
      deleteUserPolicy: "deleteUserPolicy",
    }),
    getMethodColor,
    fetchUsers() {
      this.tableLoading = true;
      this.getUsers()
        .then((res) => {
          this.users = this.allUsers.map((x) => ({
            ...x,
            ___isEditing: false,
            __loadingRole: false,
            __editingName: false,
            __nameModel: x.user_name,
            __loadingName: false,
          }));
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    fetchPolicies() {
      this.getPolicies()
        .then((res) => {
          this.policies = this.allPolicies.map((x) => ({
            ...x,
            __loading: false,
          }));
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {});
    },
    fetchImplicitPolicies(email) {
      this.policyPanel.loadingImplicit = true;
      this.getImplicitPolicies(email)
        .then((data) => {
          this.policyPanel.implicit = data.map((x) => ({
            ...x,
            __loading: false,
          }));
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          this.policyPanel.loadingImplicit = false;
        });
    },

    viewUserPolicy(item) {
      this.fetchPolicies();
      this.fetchImplicitPolicies(item.email);
      this.panelActive = true;
      this.selectedUser = {
        ...item,
      };
    },
    startAddUserRoles(item) {
      item.___isEditing = true;
    },
    endAddUserRoles(item, submit) {
      if (!submit) {
        item.__rolesModel = [];
        item.___isEditing = false;
        return;
      }
      item.__loadingRole = true;
      this.addUserRoles({
        id: item.id,
        email: item.email,
        roles: item.__rolesModel,
      })
        .then((res) => {
          item.__rolesModel = [];
          item.___isEditing = false;
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          item.__loadingRole = false;
        });
    },
    removeRole(item, role) {
      item.__loadingRole = true;
      this.deleteUserRole({
        id: item.id,
        email: item.email,
        role,
      })
        .then((res) => {})
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          item.__loadingRole = false;
        });
    },

    startEditName(item) {
      item.__editingName = true;
    },
    endEditName(item, submit) {
      if (!submit) {
        item.__nameModel = item.user_name;
        item.__editingName = false;
        return;
      }
      item.__editingName = false;
      item.user_name = item.__nameModel;
    },

    doAddUserPolicy(item) {
      item.__loading = true;
      this.addUserPolicy({
        email: this.selectedUser.email,
        policy: item,
      })
        .then(() => {
          item.__loading = false;
          this.policyPanel.implicit.push({
            Domain: "gempages",
            Role: this.selectedUser.email,
            Method: item.Method,
            Path: item.Path,
            __loading: false,
          });
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          item.__loading = false;
        });
    },
    doDeleteUserPolicy(item) {
      item.__loading = true;
      this.deleteUserPolicy({
        email: this.selectedUser.email,
        policy: item,
      })
        .then(() => {
          item.__loading = false;
          const idx = this.policyPanel.implicit.findIndex(
            (x) =>
              x.Method == item.Method &&
              x.Role == this.selectedUser.email &&
              x.Path == item.Path
          );
          if (idx > -1) {
            this.policyPanel.implicit.splice(idx, 1);
          }
        })
        .catch((err) => {
          console.error(err);
          this.OpenError({
            message: this.getErrorMessage(err),
          });
        })
        .finally(() => {
          item.__loading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.slide-panel::v-deep,
::v-deep .slide-panel {
  .slide-panel__body {
    width: 800px;
    transition: all 600ms ease;
    background: #f1f1f1;
  }
}
</style>
