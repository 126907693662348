export function getMethodColor(method) {
  const m = method.toLowerCase();
  switch (m) {
    case "get": {
      return "green--text text--darken-1";
    }
    case "post": {
      return "amber--text text--darken-1";
    }
    case "put": {
      return "blue--text text--lighten-1";
    }
    case "delete": {
      return "error--text";
    }
    case "head": {
      return "grey--text text--darken-1";
    }
    default: {
      return "";
    }
  }
}

export const commonRules = {
  required: (value) => !!value || "Required",
  min: num => (value) => (value || "").length >= num || `Minimum ${num} characters`,
  max: num => (value) => (value || "").length <= num || `Maximum ${num} characters`,
};
