<template>
  <Portal to="modals" :disabled="noPortal">
    <div class="slide-panel" :class="{ 'is-active': open }" v-bind="scopeAttrs">
      <div class="backdrop" @click="close"></div>
      <transition name="slide">
        <div class="slide-panel__body" :class="bodyClass" v-if="open">
          <VIcon color="error" class="pa-2 close-btn" @click="close">fa-times</VIcon>
          <slot></slot>
        </div>
      </transition>
    </div>
  </Portal>
</template>

<script>
import scopeAttrs from "@/mixins/scopeAttrs";
export default {
  model: {
    prop: "open",
    event: "toggle",
  },
  mixins: [scopeAttrs],
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    noPortal: {
      type: Boolean,
      default: false,
    },
    bodyClass: {
      type: String,
      default: "py-2 px-4"
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit("toggle", false);
    },
  },
};
</script>
<style lang="scss" scoped>
$transition: 0.5s ease-in-out;
.slide-panel {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  transition: z-index $transition;

  .backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: rgba(#000, 0.3);
    opacity: 0;
    transition: opacity $transition;;
  }

  .slide-panel__body {
    max-width: 100%;
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    background: #fefefe;
    height: 100%;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
    transition: all $transition;
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    margin-left: auto;
  }

  &.is-active {
    z-index: 100000;
    transition: z-index 0s linear;
    .backdrop {
      opacity: 1;
    }
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: 1s;
}
.slide-enter {
  transform: translate(100%, 0);
}
.slide-leave-to {
  transform: translate(100%, 0);
}

.close-btn {
  border: none;
  font-weight: bold;
  font-size: 2em;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5em;
}
</style>
