export default () => ({
  methods: {
    getErrorMessage(err, fallback = "Unexpected Error") {
      try {
        const msgKey = (err && err.response && err.response.data && err.response.data.message) || (err && err.message);
        return msgKey;
      } catch (error) {
        console.error({error});
        return fallback;
      }
    }
  }
});
